@import '~styles/global/config';

.app-container {
  .auth-modal {
    .modal-close-button {
      top: -5px;
      right: 5px;
      line-height: 1;

      z-index: 100;
    }

    .modal-content {
      background: black;
    }

    .authentication--container {
      background-size: auto 880px;
      position: relative;
    }
  }

  .auth-modal {
    max-width: 800px;
  }

  .auth-modal.modal-dialog {
    @media screen and (min-width: 768px) {
      width: 90%;
    }
  }

  .new-sign-up.modal-body {
    padding: 0;
  }
}
