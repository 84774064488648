@import '~styles/global/config';

.app-container {
  .PlainForgotPasswordView {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @include breakpoint-max(md) {
      overflow: scroll;
    }

    &__wrapper {
      padding: 30px;
      width: 90%;

      @include breakpoint(lg) {
        width: 600px;
      }
    }
  }
}
