@import '~styles/global/config';

.app-container {
  .JournalForgotPasswordView {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &__wrapper {
      background-color: color(white);
      padding: 30px;
      width: 90%;

      @include breakpoint(lg) {
        width: 600px;
      }
    }
  }
}
