@import '~styles/global/config';

.app-container {
  .PromotionSignupView {
    .SignupEmailStep {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        @include breakpoint(md) {
          width: 580px;
          padding: 0;
        }
      }

      &__eyebrow {
        font-size: 14px;
        line-height: 24px;
        color: color(white);
        text-transform: uppercase;

        @include breakpoint(md) {
          font-size: 15px;
          line-height: 24px;
        }
      }

      &__title {
        color: color(white);
        font-size: 32px;
        line-height: 32px;
        margin: 5px 0 0;

        @include breakpoint(md) {
          font-size: 36px;
          line-height: 40px;
        }

        &--yellow {
          color: #fbce0a;
          font-style: italic;
          display: inline-block;
          margin: 0 6px 0 0;
        }
      }

      &__content {
        color: color(white);
        margin: 10px 0 0;
        font-size: 15px;
        line-height: 21px;

        @include breakpoint(md) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      &__form {
        margin: 15px auto 0;
        width: 100%;

        @include breakpoint(md) {
          width: 400px;
        }
      }

      &__footer {
        position: absolute;
        bottom: 40px;
        width: 100%;
        text-align: center;
        color: color(white);
        font-size: 13px;
        line-height: 16px;

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
