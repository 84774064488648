@import '~styles/global/config';

.app-container {
  .EventMessage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    background: color(white);
    padding: 0 15px;
    min-height: 70px;
    filter: drop-shadow(0px 4px 4px rgba(29, 42, 57, 0.1));

    &--without-image {
      padding: 0 15px 0 5px;
    }

    &--without-close-btn {
      padding: 0 5px 0 5px !important;
    }

    &__image {
      width: 50px;
      height: 50px;
      border-radius: 2px;
    }

    &__content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__main-content {
      font-family: $font-family-franklin-condensed;
      font-weight: 400;
      text-align: left;
      flex: 1;
      padding: 15px 10px 15px 15px;
    }

    &__close-btn {
      font-size: 34px;
      line-height: 17px;
      height: 24px;
      width: 24px;
      text-align: center;
      transform: rotate(45deg);
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &__popup-arrow {
      position: absolute;
      top: -8px;
    }
  }
}
