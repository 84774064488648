@import '~styles/global/config';

.app-container {
  .DefaultUpdateEmailView {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;

      @include breakpoint(md) {
        width: 580px;
        padding: 0;
      }
    }

    &__title {
      color: color(white);
      font-size: 32px;
      line-height: 32px;
      margin: 5px 0 0;

      @include breakpoint(md) {
        font-size: 36px;
        line-height: 40px;
      }
    }

    &__form {
      margin: 15px auto 0;
      width: 100%;

      @include breakpoint(md) {
        width: 400px;
      }
    }
  }
}
