@import '~styles/global/config';

.app-container {
  .SMSInput__container--inline {
    display: flex;
    .SMSInput__input--wrapper {
      display: inherit;
      justify-content: center;
      // 93px because button is 85px plus 8px margin
      width: calc(100% - 93px);
      margin-right: 8px;
    }
    .Button {
      width: unset;
      min-width: 85px;
    }
    .SMSInput__input {
      font-family: $font-family-franklin-condensed;
    }
  }
  .SMSInput__error--light {
    color: color(red);
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 8px;
    border-radius: 2px;
  }
  .SMSInput__button {
    padding: 10px 0;
    width: 100%;
    height: unset;
    line-height: unset;
  }
  .SMSInput__text--small {
    font-family: $font-family-franklin-condensed;
    font-size: 9px;
    line-height: 14px;
  }
  .SMSInput__input--wrapper {
    border-radius: 2px;
    border: 1px solid color(gray-border);
    background-color: color(gray-light);
    padding: 3px 12px;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .SMSInput__input {
    font-size: 16px;
  }
  .SMSInput__input--placeholder input::placeholder {
    color: color(yellow);
    opacity: 0.5;
  }
  .SMSInput__button--invert {
    color: color(blue);
  }
}
