@import '~styles/global/config';

.app-container {
  .PlainSignupView {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: color(white);

    @include breakpoint-max(md) {
      overflow: scroll;
    }

    &__title {
      margin-top: 16px;
      margin-bottom: 10px;
      font-size: 36px;
      line-height: 40px;

      @include breakpoint-max(md) {
        margin-top: 20px;
        font-size: 28px;
        line-height: 32px;
      }
    }

    &__subtitle {
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 26px;

      @include breakpoint-max(md) {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__auth--wrapper {
      width: 100%;
      max-width: 580px;
      margin: 0 auto;
    }

    &__footer {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 24px;
      padding-bottom: 12px;
      font-size: 14px;
      line-height: 14px;
    }

    &__footer--cta {
      cursor: pointer;
      margin-left: 4px;

      &.link--nudge:after {
        bottom: 0px;
      }
    }
  }
}
