@import '~styles/global/config';

.app-container {
  .JournalSignupView {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: color(blue);

    @include breakpoint-max(md) {
      overflow: scroll;
    }
  }

  .JournalSignupView__wrapper {
    background-color: color(white);
    padding: 20px;
    width: 90%;

    @include breakpoint(lg) {
      width: auto;
      padding: 40px;
    }
  }

  .JournalSignupView__title {
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 40px;

    @include breakpoint-max(md) {
      margin-top: 20px;
      font-size: 28px;
      line-height: 32px;
    }
  }

  .JournalSignupView__subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;

    &--yellow {
      color: color(yellow);
      font-weight: 600;
    }

    @include breakpoint-max(md) {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .JournalSignupView__auth--wrapper {
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  }

  .JournalSignupView__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 14px;
  }

  .JournalSignupView__footer--cta {
    cursor: pointer;
    margin-left: 4px;

    &.link--nudge:after {
      bottom: 0px;
    }
  }
}
