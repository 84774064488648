@import '~styles/global/config';

.app-container {
  .ForgotPasswordForm {
    height: 100%;
    width: 100%;
    display: flex;

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;

      @include breakpoint-max(md) {
        padding: 0 20px;
        justify-content: center;
      }
    }

    &__title {
      font-family: $font-family-franklin-condensed;
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 16px;
    }

    &__message {
      font-family: $font-family-franklin-condensed;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &__input {
      display: flex;
      margin-bottom: 8px;
      padding-left: 12px;
      height: 40px;
      width: 100%;
      background: color(gray-bg);
      font-size: 16px;
      line-height: 24px;
    }

    &__button {
      height: 40px;
      width: 100%;
      margin-bottom: 16px;
      padding: 8px 18px;
      font-weight: 600;
      color: color(blue);
      background-color: color(yellow);
      cursor: pointer;

      &:hover {
        background-color: darken(color(yellow), 3%);
      }
    }

    &__link {
      cursor: pointer;
    }
  }
}
