@import '~styles/global/config';

.app-container {
  .JournalLoginView {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: color(blue);
    margin: 0 auto;

    @include breakpoint-max(md) {
      overflow: scroll;
    }
  }
  .JournalLoginView__wrapper {
    display: flex;
    flex-direction: column;
    background-color: color(white);
    width: 90%;
    padding: 10px;

    @include breakpoint(lg) {
      width: auto;
    }
  }
  .JournalLoginView__title {
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 36px;
    line-height: 40px;

    @include breakpoint-max(lg) {
      margin-top: 20px;
      font-size: 28px;
      line-height: 32px;
    }
  }
  .JournalLoginView__auth--wrapper {
    width: 100%;
    max-width: 580px;
    margin: 0 auto;

    @include breakpoint(lg) {
      width: 580px;
    }
  }
  .JournalLoginView__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 24px;
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 14px;
  }
  .JournalLoginView__footer--cta {
    cursor: pointer;
    margin-left: 4px;
    &.link--nudge:after {
      bottom: 0px;
    }
  }
}
