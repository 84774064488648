@import '~styles/global/config';

.app-container {
  .SMSImageModal {
    position: fixed;
    z-index: 999999;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    max-height: 640px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include hoverfade-mixin(fadeinzero, 0, 1);
    animation: fadeinzero 200ms ease-in-out;
    @include breakpoint-max(md) {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      height: calc(100% - 60px);
      max-height: 560px;
    }
  }
  .SMSImageModal__content {
    position: absolute;
    background-color: color(white);
    height: 100%;
    width: 100%;
    max-width: 480px;
    max-height: 325px;
    top: 25%;
    left: 25%;
    padding: 20px;
    @include breakpoint-max(md) {
      padding: 16px 20px;
      max-height: unset;
      max-width: unset;
      height: unset;
      top: unset;
      left: 0;
      bottom: 0;
    }
  }
  .SMSImageModal__overlay {
    background-color: color(blue);
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 201;
  }
  .SMSImageModal__icon {
    height: 40px;
    margin-bottom: 12px;
    @include breakpoint-max(sm) {
      height: 32px;
      margin-bottom: 8px;
    }
  }
  .SMSImageModal__title {
    font-family: $font-family-franklin-condensed;
    @include breakpoint-max(sm) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .SMSImageModal--close-btn {
    top: -8px;
    right: 8px;
    line-height: 1;
    z-index: 100;
    color: color(white);
    opacity: 0.8;
    position: absolute;
    font-size: 50px;
    padding: 2px;
    background: transparent;
    transform: rotate(45deg);
    &:hover {
      opacity: 1;
    }
  }
  .SMSImageModal__background--desktop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    z-index: -1;
    @include breakpoint-max(md) {
      display: none;
    }
  }
  .SMSImageModal__background--mobile {
    height: 50%;
    object-fit: cover;
    @include breakpoint(md) {
      display: none;
    }
  }
}
