/*
// === Config Variables ===
*/

$all-colors: (
  white: #ffffff,
  black: #222222,
  blue: #1d2b39,
  blue-bg-dark: #151e27,
  dark-blue: #203040,
  twilight-blue: #1d2a39,
  twilight-blue-faded: #1d2a39b2,
  yellow: #fbce0a,
  dark-yellow: #e8bd04,
  red: #a50014,
  gray-text: #8d8b81,
  gray-text-light: #918f85,
  gray-bg: #f1f3f3,
  gray-bg-dark: #c6cacb,
  gray-blue-light: #d7dae0,
  gray-border: #e0e2e2,
  gray-light: #f7f5f5,
  gray-blue: #454d57,
  gray-separator: #1d2a3933,
  green: #3a7d25,
  soft-green: #d0e8c8,
  soft-orange: #fddbb4,
  soft-red: #f3c1c1,
  admin-gray-bg: #f9fafb,
  admin-gray-bg-light: #dee2e6,
  admin-gray-bg-dark: #f0f0f0,
  admin-gray-light: #9d9fa2,
  admin-gray-dark: #737577,
  admin-blue: #157efb,
  admin-purple: #b274ff,
  admin-blue-light: #deedf4,
  admin-gray-li-bg: #d7dae0,
  admin-gray-dash-border: #c4c4c4,
  red-orange: #fd441b,
  bronze: #ca8136,
  grayish-blue: #bfc6cc,
  facebook-blue: #3b5998,
  google-blue: #5284ec,
  paypal-blue: #199dda,
  cyber-week-green: #52ff6e
);

$admin-shadow: 0px 10px 11px 0px #00000020;

$breakpoints: (
  xxs: 374px,
  xs: 400px,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: 1200px,
  xxl: 1600px
);

$column-size: 12;

$grid-gutter-mobile: 10px;
$grid-gutter-desktop: 25px;
$grid-gutter-extra: (
  none: 0px,
  xsmall: 5px,
  small: $grid-gutter-mobile,
  medium: 20px,
  default: $grid-gutter-desktop
);

$grid-padding-mobile: 20px;
$grid-padding-tablet: 20px;
$grid-padding-desktop: 60px;
$grid-max-width: 1400px;

$font-size-body-mobile: 14px;
$line-height-body-mobile: 18px;
$font-size-body-desktop: 16px;
$line-height-body-desktop: 20px;
$line-height-text: 1.15;
$line-height-paragraph: 1.4;
$line-height-body: 1.6;

$font-family-franklin-condensed: 'Franklin Gothic Condensed', sans-serif;
$font-family-franklin: 'Franklin Gothic', sans-serif;
$font-family-freight: 'Freight Text', sans-serif;
$font-family-duper-italic: 'duper', sans-serif;
$font-family-holiday: 'Holiday Font', sans-serif;

$input-height: 40px;
$input-height-desktop: 50px;

$button-height: 50px;
$button-font-size: 14px;
$button-padding: 16px;

$tooltip-z-index: 100;

$header-mobile-height: 60px;
$header-mobile-subheader-height: 60px;
$header-mobile-z-index: 200;

$announcement-desktop-height: 45px;
$announcement-mobile-height: 35px;

$header-desktop-height: 50px;
$header-desktop-border-height: 3px;
$header-desktop-z-index: 200;

$desktop-dropdown-height: 520px;
$desktop-dropdown-height-sc: 535px; // will switch to this dropdown height after done updating nav to 4:5

$mini-cart-z-index: 20000;
$mini-cart-max-width: 480px;
$mini-cart-header-height-mobile: 60px;
$mini-cart-header-height-desktop: 65px;
$mini-cart-header-height-desktop-error: 68px;
$mini-cart-footer-height: 235px;

$cart-page-max-width: 860px;
$cart-footer-height-mobile: 80px;
$cart-footer-height-desktop: 100px;

$pdp-mobile-column-width: 520px;

$modal-z-index: 600;

$width-sidebar: 200px;

$underlay-opacity: 62%;

/*
// === Config Getter Functions ===
*/

@function color($color-name) {
  $color-found: map-has-key($all-colors, $color-name);
  @if ($color-found == true) {
    @return map-get($all-colors, $color-name);
  } @else {
    @warn "Color " + #{$color-name} + " doesn't exist.";
  }
}

@mixin breakpoint($size) {
  $breakpoint-found: map-has-key($breakpoints, $size);
  @if ($breakpoint-found == true) {
    $breakpoint: map-get($breakpoints, $size);
    @media (min-width: #{$breakpoint}) {
      @content;
    }
  } @else {
    @warn "Breakpoint size " + #{$size} + " doesn't exist.";
  }
}

@mixin breakpoint-max($size) {
  $breakpoint-found: map-has-key($breakpoints, $size);
  @if ($breakpoint-found == true) {
    $breakpoint: map-get($breakpoints, $size);
    @media (max-width: #{$breakpoint}) {
      @content;
    }
  } @else {
    @warn "Breakpoint size " + #{$size} + " doesn't exist.";
  }
}

@mixin breakpoint-min-max($min-size, $max-size) {
  $breakpoint-min-found: map-has-key($breakpoints, $min-size);
  $breakpoint-max-found: map-has-key($breakpoints, $max-size);

  @if ($breakpoint-min-found == true and $breakpoint-max-found == true) {
    $breakpoint-min: map-get($breakpoints, $min-size);
    $breakpoint-max: map-get($breakpoints, $max-size);

    @media (min-width: #{$breakpoint-min}) and (max-width: #{$breakpoint-max}) {
      @content;
    }
  } @else {
    @warn "Breakpoint sizes " + #{$min-size} + " and " + #{$max-size} + " don't exist.";
  }
}

@function gutter($gutter-name) {
  $gutter-found: map-has-key($grid-gutter-extra, $gutter-name);
  @if ($gutter-found == true) {
    @return map-get($grid-gutter-extra, $gutter-name);
  } @else {
    @warn "Gutter " + #{$gutter-name} + " doesn't exist.";
  }
}

@mixin hoverfade-mixin($name, $from, $to) {
  @keyframes #{$name} {
    from {
      opacity: $from;
    }
    to {
      opacity: $to;
    }
  }
}

@mixin contact-button {
  transition: background 0.3s;
  font-family: $font-family-franklin-condensed;
  font-size: 0.8em;
  font-weight: bold;
  color: color(white);
  background-color: color(black);
  padding: 1em 3em;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: color(gray-bg-dark);
  }
}
