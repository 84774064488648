@import '~styles/global/config';

.app-container {
  .BackInStockSignupView {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: color(blue);
    margin-top: 56px;

    @include breakpoint-max(md) {
      overflow: scroll;
      margin-top: 46px;
    }

    &__title {
      font-size: 28px;
      line-height: 32px;

      @include breakpoint-max(lg) {
        margin-top: 12px;
      }
    }

    &__subtitle {
      margin: 8px 0 16px;
      font-size: 14px;
      line-height: 18px;

      @include breakpoint-max(lg) {
        width: 50%;
      }
    }

    &__tree-logo {
      height: 40px;
      margin-bottom: 12px;

      @include breakpoint-max(md) {
        margin-bottom: 0;
      }
    }

    &__auth--wrapper {
      width: 100%;
      max-width: 580px;
      margin: 0 auto;
    }

    &__footer {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 24px;
      padding-bottom: 12px;
      font-size: 14px;
      line-height: 14px;
    }

    &__footer--cta {
      cursor: pointer;
      margin-left: 4px;

      &.link--nudge:after {
        bottom: 0px;
      }
    }
  }
}
