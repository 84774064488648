@import '~styles/global/config';

.app-container {
  .AlertMessages {
    position: fixed;
    top: 0;
    z-index: 201;
    width: 100%;
    text-align: center;
    background: rgba(color(dark-blue), 0.9);
    color: color(white);
    font-size: 0.75em;
    line-height: 1.4em;
    padding: 1.1em 1em;
  }
  .AlertMessage__success {
    background: rgba(color(green), 0.9);
  }
  .AlertMessage__error {
    background: rgba(color(red), 0.9);
  }
}

.content-wrapper {
  .AlertMessages {
    position: fixed;
    z-index: 2000;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    background: rgba(color(dark-blue), 0.9);
    color: color(white);
    font-size: 1.4em;
    line-height: 1.4em;
    padding: 1.1em 1em;
  }

  .AlertMessage__success {
    background: rgba(color(green), 0.9);
  }

  .AlertMessage__error {
    background: rgba(color(red), 0.9);
  }
}
