@import '~styles/global/config';

.app-container {
  .PasswordForm {
    &__input-wrapper {
      margin-bottom: 8px;
      position: relative;
    }

    &__button {
      margin: 8px 0;
    }

    &__login {
      margin: 20px 0 0;
      color: color(white);
      font-size: 14px;
      line-height: 14px;
    }
    &__link {
      text-decoration: underline;
      cursor: pointer;
    }

    &__suggestion-icon {
      width: 14px;
      cursor: pointer;
    }

    &__message-wrapper {
      position: absolute;
      top: 68px;
      right: 0;
      left: 0;
      z-index: 1;
      font-size: 14px;
      line-height: 16px;
      color: color(blue);

      .EventMessage__popup-arrow {
        left: 106px;
      }
    }
  }
}
