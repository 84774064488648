@import '~styles/global/config';

.app-container {
  .Button {
    padding: 0 4px;
    width: 100%;
    height: 40px;
    background: #fbce0a;
    border-radius: 2px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: color(blue);

    &.cyberweek-green {
      background-color: color(cyber-week-green);
    }
  }
}
