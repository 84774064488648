@import '~styles/global/config';

.app-container {
  .Input {
    input {
      font-family: $font-family-franklin-condensed;
      display: flex;
      padding: 0 12px;
      height: 40px;
      width: 100%;
      background: color(gray-bg);
      border-radius: 2px;
      font-size: 16px;
      line-height: 24px;
      color: color(blue);
      align-items: center;
    }

    input:focus {
      border: solid 1px color(gray-bg-dark);
    }

    input::placeholder {
      letter-spacing: initial;
      font-size: 16px;
      color: color(blue);
    }

    input:read-only {
      color: rgba(29, 42, 57, 0.3);
    }

    &__suggestion {
      color: color(white);
      font-size: 12px;
      line-height: 16px;
      margin-top: 3px;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 3px;

      & > button {
        text-decoration: underline;
        color: color(white);
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__error {
      margin-top: 3px;
      color: color(white);
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      gap: 3px;

      & > button {
        text-decoration: underline;
        color: color(white);
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
