@import '~styles/global/config';

.app-container {
  .SocialButtons {
    &__separator {
      line-height: 24px;
      width: 100%;
      margin: 0 auto;
      color: color(white);
      overflow: hidden;

      &:after,
      &:before {
        content: '';
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
        background-color: color(white);
        opacity: 0.8;
      }

      &:before {
        right: 0.5em;
        margin-left: -50%;
      }

      &:after {
        left: 0.5em;
        margin-right: -50%;
      }
    }

    &__wrapper {
      display: flex;
      margin: 8px 0 0;
    }

    &__button {
      height: 40px;
      flex: 1;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-right: 4px;
      }

      &:last-child {
        margin-left: 4px;
      }

      p {
        margin-right: 5px;
      }
    }
  }
}
