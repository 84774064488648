@import '~styles/global/config';

.app-container {
  .SoftGate__modal {
    position: fixed;
    border-radius: 5px;
    z-index: 999999;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include hoverfade-mixin(fadeinzero, 0, 1);
    animation: fadeinzero 200ms ease-in-out;
  }
  .SoftGate__modal--internal-overlay {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00000033;
  }
  .SoftGate__header-logo {
    display: none;
    position: absolute;
    top: 40px;
    width: 100%;
    text-align: center;
  }

  // replace to text-sm or new preset after typography update
  .SoftGate__header-subtitle {
    font-size: 14px;
    line-height: 24px;
    font-family: $font-family-franklin;
    color: color(white);
    text-transform: capitalize;

    &.black {
      color: color(black);
    }
  }
  // end of replacement
  .SoftGate--close-btn {
    top: -8px;
    right: 8px;
    line-height: 1;
    z-index: 100;
    color: color(white);
    opacity: 0.8;
    position: absolute;
    font-size: 50px;
    padding: 2px;
    background: transparent;
    transform: rotate(45deg);
    &:hover {
      opacity: 1;
    }
    &--invert {
      color: color(blue);
    }
  }
  .SoftGate__overlay {
    background-color: #000000;
    opacity: 0.8;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 201;
  }
  .SoftGate__modal--full,
  .SoftGate__modal--short {
    .SoftGate__header-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .SoftGate__modal--short {
    max-width: 980px;
    max-height: 820px;
    .WelcomeView__subtitle {
      max-width: 550px;
    }
    @include breakpoint(xl) {
      max-width: 1150px;
    }
    @include breakpoint-max(md) {
      max-height: 90%;
      max-width: 95%;
      .SoftGate__header-logo {
        top: 26px;
      }
    }
  }
  .SoftGate__modal--short,
  .SoftGate__modal--portrait {
    .ForgotPasswordView__form {
      padding: 0;
    }
    @include breakpoint-max(md) {
      .AuthForm__form {
        width: calc(100% - 40px);
      }
      .ForgotPasswordView__form {
        padding: 0 20px;
      }
    }
    @include breakpoint-max(xxs) {
      max-width: 100%;
    }
  }
  .SoftGate__modal--portrait {
    background-color: color(white);
    max-width: 500px;
    max-height: 600px;
    border-radius: 2px;
    .SoftGate--close-btn {
      color: color(blue);
    }
    .AuthForm__button {
      background-color: color(blue);
      color: color(white);
      &:hover {
        background-color: color(blue);
        opacity: 0.9;
      }
    }
    .AuthForm__or:before,
    .AuthForm__or:after {
      color: color(gray-bg);
    }
    @include breakpoint-max(md) {
      width: calc(100% - 40px);
    }
    @include breakpoint-max(xxs) {
      width: 100%;
    }
  }
  .SoftGate__image--mobile,
  .SoftGate__image--desktop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    z-index: -1;
  }
  .SoftGate__image--desktop {
    @include breakpoint-max(md) {
      display: none;
    }
  }
  .SoftGate__image--mobile {
    @include breakpoint(md) {
      display: none;
    }
  }
}
.SoftGate__no-scroll {
  overflow: hidden;
}
