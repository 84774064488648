.app-container {
  .PromotionSignupView {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // Eppo temporary styling
  .temp-padding-for-exp {
    padding-top: 120px !important;
  }
}
