@import '~styles/global/config';

.app-container {
  .AuthForm__form {
    width: calc(100% - 100px);
    margin: 0 auto;
    @include breakpoint-max(lg) {
      width: calc(100% - 50px);
    }
    @include breakpoint-max(md) {
      width: calc(100% - 32px);
    }
  }
  .AuthForm__input--row {
    input {
      display: flex;
      margin: 8px auto 4px;
      padding-left: 12px;
      height: 40px;
      width: 100%;
      background: color(gray-bg);
      border-radius: 2px;
      font-size: 16px;
    }
    input:focus {
      border: solid 1px color(gray-bg-dark);
    }
    input[type='password'] {
      font-size: 18px;
    }
    input[type='password']::placeholder {
      letter-spacing: initial;
      font-size: 16px;
    }
    .input__row {
      margin-bottom: 0;
    }
  }
  .AuthForm__container--password {
    position: relative;
  }
  .AuthForm__eyeball {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
  }
  .AuthForm__input--password-small {
    text-align: left;
    margin-bottom: 8px;
  }
  .AuthForm__captcha {
    display: flex;
    justify-content: center;
  }
  .AuthForm__button {
    height: 40px;
    width: 100%;
    margin: 8px auto 0;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    background-color: color(yellow);
    color: color(blue);

    cursor: pointer;
    @include breakpoint-max(md) {
      line-height: 24px;
    }

    &:hover {
      background-color: darken(color(yellow), 3%);
    }

    &.AuthForm__button--dark {
      color: color(white);
      background-color: color(blue);
      &:hover {
        background-color: lighten(color(blue), 5%);
      }
    }
  }
  .AuthForm__or {
    line-height: 24px;
    overflow: hidden;
    text-align: center;
    padding: 8px;
    width: calc(100% - 100px);
    margin: 0 auto;

    &:after,
    &:before {
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
      background-color: color(white);
      opacity: 0.8;
    }

    &.color--blue {
      &:before,
      &:after {
        opacity: 0.3;
        background-color: color(twilight-blue-faded);
      }
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
    @include breakpoint-max(lg) {
      width: calc(100% - 50px);
      &:before,
      &:after {
        content: none;
      }
    }
  }

  .AuthForm__auth-buttons {
    display: flex;
    width: calc(100% - 92px);
    margin: 0 auto;
    @include breakpoint-max(lg) {
      width: 100%;
    }
    @include breakpoint-max(md) {
      width: calc(100% - 40px);
    }
  }
  .AuthForm__auth--button {
    height: 40px;
    flex: 1;
    padding: 0px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: unset;
    &:first-child {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
    p {
      margin-right: 5px;
    }
  }
  .AuthForm__password-reset {
    font-family: $font-family-franklin-condensed;
    font-size: 16px;
    margin-top: 16px;
    cursor: pointer;
  }

  // Cyber week styles
  .AuthForm__form--cyberweek {
    input {
      background: rgba(241, 243, 243, 0.5) !important;

      &::placeholder {
        color: color(white);
      }
    }
  }

  .AuthForm__button--cyberweek {
    width: calc(100% - 100px);
    border-radius: 20px;
    letter-spacing: 0.03em;
    background: color(white);
    font-size: 18px;
    margin-top: 16px;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: color(red);
    }
  }
}
