@import '~styles/global/config';

.app-container {
  .SoftGateBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    z-index: -1;

    &--desktop {
      @include breakpoint-max(lg) {
        display: none;
      }
    }

    &--mobile {
      @include breakpoint(lg) {
        display: none;
      }
    }
  }
}
