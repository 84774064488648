@import '~styles/global/config';

.app-container {
  .EmailForm__button {
    margin: 8px 0;
  }

  .EmailForm__login {
    margin: 20px 0 0;
    color: color(white);
    font-size: 14px;
    line-height: 14px;
  }
  .EmailForm__login__link {
    text-decoration: underline;
    cursor: pointer;
  }

  .EmailForm__inline {
    display: flex;
    .Input {
      // 93px because button is 85px plus 8px margin
      width: calc(100% - 93px);
      margin-right: 8px;
      input::placeholder {
        color: color(blue);
        opacity: 0.5;
      }
    }
    .EmailForm__button {
      margin: 0;
      width: auto;
      .Button {
        min-width: 85px;
      }
    }
    @include breakpoint-max(md) {
      margin: 0 auto;
    }
  }
}
