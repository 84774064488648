@import '~styles/global/config';

.app-container {
  .JournalWelcomeView {
    display: flex;
    flex-direction: column;
    color: color(black);
    max-width: 800px;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @include breakpoint-max(md) {
      padding: 0 20px;
      overflow: scroll;
    }

    .yellow {
      color: color(yellow);
    }

    .italic {
      font-style: italic;
    }
  }

  .JournalWelcomeView__form-wrapper {
    background-color: color(white);
    padding: 30px 50px;
  }

  .JournalWelcomeView__eyebrow {
    font-size: 16px;
    line-height: 18px;
    color: #616a74;
    margin-bottom: 8px;

    @include breakpoint-max(md) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .JournalWelcomeView__title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    color: color(blue);
    margin-bottom: 4px;

    @include breakpoint-max(md) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .JournalWelcomeView__subtitle {
    font-size: 20px;
    line-height: 24px;
    color: color(blue);

    @include breakpoint-max(md) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .JournalWelcomeView__button {
    height: 40px;
    width: 80%;
    margin: 20px auto 0;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    background-color: color(yellow);
    color: color(blue);
    cursor: pointer;

    @include breakpoint-max(md) {
      line-height: 24px;
    }

    &:hover {
      background-color: darken(color(yellow), 3%);
    }
  }

  .JournalWelcomeView__footer {
    font-size: 16px;
    line-height: 22px;
    margin-top: 30px;
  }

  .JournalWelcomeView__footer--cta {
    cursor: pointer;
    margin-left: 4px;
  }
}
