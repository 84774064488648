@import '~styles/global/config';

.app-container {
  .PasswordInput {
    position: relative;

    &__eyeball {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 5px;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
